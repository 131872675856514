<script>
import { ref, watch, defineComponent, onMounted } from 'vue';
import PercentageCircle from "@/components/PercentageCircle.vue";
import IconButton from "@/components/IconButton.vue";

export default defineComponent({
  components: {
    PercentageCircle,
    IconButton
  },
  props: {
    item: Object,
    slimLayout: Boolean
  },
  setup(props) {
    let iconClass = ref('');
    let tooltip = ref('');
    let additionalClasses = ref('');

    function checkWatchListStatus() {
      if (props.item.onWatchlist) {
        return {
          iconClass: 'bi-dash-square',
          tooltip: 'Remove from Watchlist',
          additionalClasses: 'text-black hover:text-red'
        };
      } else {
        return {
          iconClass: 'bi-plus-square',
          tooltip: 'Add to Watchlist',
          additionalClasses: 'text-black hover:text-green-1'
        };
      }
    }

    function updateItem() {
      const result = checkWatchListStatus();
      iconClass.value = result.iconClass;
      tooltip.value = result.tooltip;
      additionalClasses.value = result.additionalClasses;
    }

    watch(() => props.item.onWatchlist, updateItem);

    onMounted(() => {
      updateItem();
    });

    return { iconClass, tooltip, additionalClasses };
  }
});
</script>



<template>
  <div id="card" class="flex flex-col items-center border rounded-lg shadow md:flex-row hover:bg-gray-2 max-h-40">
    <div class="flex flex-grow items-center justify-between w-full p-4">
      <div class="flex flex-col justify-center items-start w-2/3">
        <div v-if="!slimLayout">
          <p> {{ item.title }}</p>
          <p class="text-sm text-gray">{{ item.addDate }}</p><br>
          <p >Watched: {{ item.watchCounter }}</p>
        </div>
        <div v-else>
          <p>{{ item.title }}</p>
        </div>

      </div>
        <div v-if="!slimLayout" class="flex justify-center items-end w-1/3">
        <PercentageCircle :percentage="item.ratingValue"></PercentageCircle>
      </div>
        <div v-if="!slimLayout" class="flex flex-col justify-center items-end w-0.5/3">
          <icon-button icon-class="bi bi-trash2-fill"
                       tooltip="Delete Record"
                       additional-classes="text-red hover:text-black"
                        @click="this.$emit('deleteItem')"
          >
          </icon-button>
          <icon-button :icon-class="iconClass"
                       :tooltip="tooltip"
                       :additional-classes="additionalClasses"
                       @click="this.$emit('switchWatchList')"
          >
          </icon-button>
          <icon-button icon-class="bi bi-plus-lg"
                       tooltip="Watched +1 time"
                       additional-classes="hover:text-green-3"
                       @click="this.$emit('increaseWatchCounter')"
          >
          </icon-button>
      </div>
        <div v-if="slimLayout" class="flex flex-col justify-center items-end w-1/3">
          <icon-button :icon-class="iconClass"
                       :tooltip="tooltip"
                       :additional-classes="additionalClasses"
                       @click="this.$emit('switchWatchList')"
          >
          </icon-button>
        </div>
    </div>
  </div>
</template>
