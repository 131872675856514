<script setup>
import { ref, watch, onMounted } from 'vue';
import {
  fetchDataFromDatabase,
  deleteDataEntryInDatabase,
  addDataEntryToWatchlist,
  updateDataEntryInDatabase, deleteDataEntryToWatchlist
} from '@/assets/serverAction';
import { filterArrayByString } from '@/assets/Utils';
import DataEntry from "@/components/DataEntry.vue";
import ToggleButton from "@/components/ToggleBtn.vue";
import IconButton from "@/components/IconButton.vue";

const movies = ref({});
const shows = ref({});
const loadedData = ref({});
let search = ref('');
let checkedFilters = ref([]);
let selectedSortingMethod = ref('');
let switchToggled = ref(false);


watch(checkedFilters, () => {
  filterItemsOnSelectedMethod();
});

watch(selectedSortingMethod, () => {
  filterItemsOnSelectedSortingMethod();
});

onMounted(() => {
  handleFetchData('movies');
  handleFetchData('shows');
});

function handleFetchData(type){
  fetchDataFromDatabase(type).then(response => {
    if(response.data)
    {
      if (type === 'movies') {
        movies.value = response.data;
        loadedData.value = movies.value;
      } else if (type === 'shows') {
        shows.value = response.data;
        loadedData.value = movies.value;
      }
    }
    else {
      console.log('Error while fetching data: ', response);
      loadedData.value = null;
    }
  });
}

//Filter Array with sorting methods
function filterItemsOnSelectedSortingMethod(){
  let items = loadedData.value;

  switch(selectedSortingMethod.value){
    case 'alpha':
      items = items.sort((a, b) => a.title.localeCompare(b.title));
      break;
    case 'rating':
      items = items.sort((a, b) => b.ratingValue - a.ratingValue);
      break;
    case 'addedDate':
      items = items.sort((a, b) => new Date(b.addDate) - new Date(a.addDate));
      break;
    case 'watchCounter':
      items = items.sort((a, b) => b.watchCounter - a.watchCounter);
      break;
  }
  updateFilteredItems(items);
}

//EventHandler for the Filter
function filterItemsOnSelectedMethod() {
  let filteredItems = loadedData.value;

  if (checkedFilters.value.includes('watched')) {
    filteredItems = filteredItems.filter(item => item.watchCounter > 0);
  }
  if (checkedFilters.value.includes('notWatched')) {
    filteredItems = filteredItems.filter(item => item.watchCounter === 0);
  }
  if (checkedFilters.value.includes('watchlist')) {
    filteredItems = filteredItems.filter(item => item.onWatchlist);
  }

  updateFilteredItems(filteredItems);
}

//Update the filtered items
function updateFilteredItems(filteredItems) {
  loadedData.value = filteredItems;
}

//Toggle between Movies and TVShows
function toggleDataEntrys(state){
  switchToggled.value = state;
  loadedData.value = switchToggled.value ? shows.value : movies.value;
  console.log('Switch Toggled: ', loadedData.value);
}

//Delete an item from the database
async function handleDelete(id){

  if(confirm('Eintrag wirklich Löschen?')) {
    const typeValue = switchToggled.value ? 'show' : 'movie';

    const response = await deleteDataEntryInDatabase(id, typeValue);
    if(response.status === 200) {
      loadedData.value = loadedData.value.filter(item => item.id !== id);
    }
    else{
      window.alert('Error while deleting item');
    }


  }
}

//Update the watchlist status
function updateWatchListStatus(item){

  const typeValue = switchToggled.value ? 'show' : 'movie';

  if(item.onWatchlist){
    deleteDataEntryToWatchlist(item.id, typeValue).then(response => {
      if(response.status === 200)
      {
        item.onWatchlist = !item.onWatchlist;
      }
    }).catch(error => {
      console.log('Error while updating watchlist status: ', error)});
  } else {
    addDataEntryToWatchlist(item.id, typeValue).then(response => {
      if(response.status === 200)
      {
        item.onWatchlist = !item.onWatchlist;
      }
    }).catch(error => {
      console.log('Error while updating watchlist status: ', error)});
  }
}

function checkLoadedData(){
  const array = loadedData.value;
  return array.length >= 1;
}
//Increase the watchCounter
async function handleIncreaseWatchlistStatus(item) {
  const typeValue = switchToggled.value ? 'show' : 'movie';

  const copyItem = JSON.parse(JSON.stringify(item));
  copyItem.watchCounter++;

  const response = await updateDataEntryInDatabase(item.id, copyItem, typeValue);
  if(response.status === 200)
  {
    item.watchCounter++;
  }
}

</script>


<template>
  <div class="flex">
    <div class="w-3/12 bg-gray-2 p-5">
      <nav>
        <ul class="space-y-2 font-medium">
          <li class="mb-7">
            <ToggleButton :toggled="switchToggled"  @switchToggled="toggleDataEntrys($event)" />
          </li>
          <li class="mb-7">
            <input type="search" class="w-full p-2 pl-4 border rounded-lg bg-white" placeholder="Search..." v-model="search" v-on:input="loadedData = filterArrayByString(loadedData, search)"/>
          </li>
          <li class="mb-7">
            <p class="text-sm font-bold mb-2">Sort by:</p>
            <select class="w-full p-2 border rounded-lg bg-gray-1" v-model="selectedSortingMethod" v-on:change="filterItemsOnSelectedSortingMethod">
              <option disabled value="">Filter</option>
              <option value="alpha">Alphabetical</option>
              <option value="rating">Rating</option>
              <option value="addedDate">Added Date</option>
              <option value="watchCounter">Watch Counter</option>
            </select>
          </li>
          <li>
            <p class="text-sm font-bold mb-2">Filters:</p>
            <div class="grid grid-cols-2 gap-1.5">
              <div>
                <input type="checkbox" id="Watched" value="watched" class="hidden peer" v-model="checkedFilters">
                <label for="Watched" class="inline-flex border border-1 items-center justify-center w-full py-1 p-3 bg-white rounded cursor-pointer hover:text-blue-1 hover:bg-gray-1 peer-checked:border-blue-1 peer-checked:text-blue-1 peer-checked:bg-blue-2 peer-checked:bg-opacity-5">Watched</label>
              </div>
              <div >
                <input type="checkbox" id="Not Watched" value="notWatched" class="hidden peer" v-model="checkedFilters">
                <label for="Not Watched" class="inline-flex border border-1 items-center justify-center w-full py-1 p-3 bg-white rounded cursor-pointer hover:text-blue-1 hover:bg-gray-1 peer-checked:border-blue-1 peer-checked:text-blue-1 peer-checked:bg-blue-2 peer-checked:bg-opacity-5">Not Watched</label>
              </div>
              <div >
                <input type="checkbox" id="Watchlist" value="watchlist" class="hidden peer" v-model="checkedFilters">
                <label for="Watchlist" class="inline-flex border border-1 items-center justify-center w-full py-1 p-3 bg-white rounded cursor-pointer hover:text-blue-1 hover:bg-gray-1 peer-checked:border-blue-1 peer-checked:text-blue-1 peer-checked:bg-blue-2 peer-checked:bg-opacity-5">Watchlist</label>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <div class="w-9/12">
      <div class="container p-10 max-h-full" style="overflow-y: scroll;">
        <DataEntry v-if="checkLoadedData()" v-for="(item, index) in loadedData" :key="item.id"
                   class="mb-5"
                   :item="item"
                   @deleteItem="handleDelete(item.id)"
                   @switchWatchList="updateWatchListStatus(item)"
                   @increaseWatchCounter="handleIncreaseWatchlistStatus(item)"
        />
        <div v-else class="text-center border-4 rounded-3xl border-dashed border-blue-1 p-10">
          <h3>No Items found</h3>
            <icon-button icon-class="" text="Add a new Entry" tooltip="Open a modal to create a new entry" additional-classes="text-blue-1 bg-green-1 bg-opacity-50 w-auto mt-2" @click="this.$emit('openModal')"></icon-button>
        </div>
      </div>
    </div>
  </div>
</template>
