const BASEURL = 'https://api.film-heinz.karawedi.de/api/v1/'

//CREATE
async function createDataEntryInDatabase(dataEntry, type)
{
    const queryEndpoint = "create/"+type;

    if (!dataEntry || !type) return { status: 400, data: null };

    return await request(queryEndpoint, "POST", dataEntry);
}

//DELETE
async function deleteDataEntryInDatabase(dataEntryId, type){
    const queryEndpoint = "delete/"+type+"/"+dataEntryId;

    if (!dataEntryId) return { status: 400, data: null };

    return await request(queryEndpoint, "DELETE", null);
}

async function deleteDataEntryToWatchlist(dataEntryId){
    const queryEndpoint = "watchlist/remove/"+dataEntryId;

    if (!dataEntryId) return { status: 400, data: null };

    return await request(queryEndpoint, "DELETE", null);

}

async function clearWatchlist() {
    const queryEndpoint = "watchlist/clear";

    return await request(queryEndpoint, "DELETE", null);
}
//GET
async function fetchDataFromDatabase(type) {
    const queryEndpoint = "get/"+type;

    if(!type) return { status: 400, data: null };

    return await request(queryEndpoint, "GET", null);
}

async function fetchSingleDataFromDatabase(dataEntryId, type){
    const queryEndpoint = "get/"+type+"/"+dataEntryId;

    if(!dataEntryId || !type) return { status: 400, data: null };

    return await request(queryEndpoint, "GET", null);
}

async function addDataEntryToWatchlist(dataEntryId, type){
    const queryEndpoint = "watchlist/add/"+type+"/"+dataEntryId;

    if (!dataEntryId || !type) return { status: 400, data: null };

    return await request(queryEndpoint, "GET", null);
}
//UPDATE

async function getWatchlist(){
    const queryEndpoint = "get/watchlist";

    return await request(queryEndpoint, "GET", null);
}

async function updateDataEntryInDatabase(dataEntryId, updatedData, type){
    const queryEndpoint = "update/"+type+"/"+dataEntryId;

    if (!dataEntryId || !updatedData || !type) return { status: 400, data: null };

    return await request(queryEndpoint, "PUT", updatedData);

}

export { createDataEntryInDatabase, getWatchlist, clearWatchlist, deleteDataEntryInDatabase, fetchDataFromDatabase, fetchSingleDataFromDatabase, updateDataEntryInDatabase, addDataEntryToWatchlist, deleteDataEntryToWatchlist };


async function request(uniqueURL, method, body){

    let result = null;

    if (!body) {
        result = await fetch(BASEURL + uniqueURL, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    else {
         result = await fetch(BASEURL + uniqueURL, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
    }



    if (result.status === 200)
    {
        return { status: 200, data: await result.json() };
    }
    else
    {
        console.log("Error while ServerRequest: " + result.statusText);
        return { status: result.status, data: null };
    }
}
