<script setup>
import {deleteDataEntryToWatchlist, getWatchlist, clearWatchlist} from "@/assets/serverAction";
import { ref } from "vue";
import DataEntry from "@/components/DataEntry.vue";
import IconButton from "@/components/IconButton.vue";

const watchlist = ref([]);

getWatchlist().then(response => {
  if(response.data) {
    watchlist.value = response.data;
  } else {
    console.log('Error while fetching data: ', response);
    watchlist.value = null;
  }
});

async function removeFromWatchlist(item){
  const result = await deleteDataEntryToWatchlist(item.id);

  if(result.status !== 200) {
    window.alert("Error: Could not remove item from watchlist");
  }else {
    window.alert("Item removed from watchlist")
    watchlist.value = watchlist.value.filter((entry) => entry.id !== item.id);
  }
}

async function handleDeleteBtnClick(){
  const result = await clearWatchlist();

  if(result.status !== 200) {
    window.alert("Error: Could not remove all items from watchlist");
  }else {
    window.alert("All items removed from watchlist")

    getWatchlist().then(response => {
      if(response.data) {
        watchlist.value = response.data;
      } else {
        console.log('Error while fetching data: ', response);
        watchlist.value = null;
      }
    });

  }
}

</script>

<template>
  <div class="flex justify-center items-center m-5 h-full">
    <div class="w-6/12 h-full">
      <div class="flex items-center justify-end p-5">
        <icon-button text="Clear all" tooltip="Clear Watchlist" additional-classes="hover:text-red" @click="handleDeleteBtnClick">
        </icon-button>
      </div>
      <div class="container max-h-full p-5">
        <div v-if="watchlist.length < 1" class="text-center border-4 rounded-3xl border-dashed border-blue-1 p-10">
          <h3>No Items found</h3>
          <router-link to="/" class="text-blue-2">
            <icon-button text="Add Entrys" tooltip="Go to Movies" additional-classes="text-blue-1 bg-green-1 bg-opacity-50 w-auto mt-2" @click="triggerModal"></icon-button>
          </router-link>
        </div>
        <DataEntry v-else v-for="(item) in watchlist" :key="item.id"
                   class="mb-5"
                   :item="item"
                   @switchWatchList="removeFromWatchlist(item)"
                   :slim-layout="true"
        />
      </div>
    </div>
  </div>
</template>
