<template>
    <header-component class="h-min" @openModal="this.showModal = true"></header-component>
    <div class="min-h-5/6 max-h-5/6">
      <ModalComponent v-if="this.showModal" @closeModal="this.showModal = false"></ModalComponent>
      <router-view></router-view>
    </div>
   <footer-component class="h-min"></footer-component>
</template>

<script>
import { defineComponent } from 'vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';

export default defineComponent({
  components: {
    FooterComponent,
    HeaderComponent,
    ModalComponent
  },
  data(){
    return {
      showModal: false
    }
  }
});
</script>
