
//Filter an array of objects (with a title Property) by a string value
export function filterArrayByString(mainArr, searchText) {

    if (searchText === '') {
        return mainArr;
    }

    searchText = searchText.toLowerCase();
    const result = mainArr.filter(itemObj => {
        return searchText.toLowerCase()
            .split(' ')
            .every(v => itemObj.title.toLowerCase().includes(v));
    });

    return result;
};
