<script setup>

</script>

<template>
  <h1>Profile</h1>
  <h1>Watchlist</h1>
  <h1>Einstellungen</h1>
</template>

<style scoped>

</style>
