<template>
  <button data-te-toggle="tooltip"
          data-te-placement="top"
          data-te-ripple-init
          data-te-ripple-color="light"
          :title="tooltip"
          type="button"
          :class="computedBtnClasses">
    <i :class="iconClass"></i>
    <span v-if="text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    iconClass: String,
    text: String,
    tooltip: String,
    additionalClasses: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      btnClasses: 'text-black hover:bg-gray-1 font-montserrat py-2 px-8 font-medium rounded-xl transition-all duration-300'
    }
  },
  computed: {
    computedBtnClasses() {
      return `${this.btnClasses} ${this.additionalClasses}`;
    }
  }
}
</script>
