<script>
import IconButton from './IconButton.vue';
import { createDataEntryInDatabase } from "@/assets/serverAction";

const defaultForm = {
    title: '',
    rating: 0,
    watchCounter: 0,
    watchlist: false,
    type : ''
}

const formKey = 'tempEntry';

export default {
    name: 'ModalComponent',
    components: {
        IconButton
    },
    data() {
        return {
            form: {...defaultForm}
        }
    },
    methods: {
        storeEntry() {
            let fieldList = {
                "title": this.form.title,
                "rating": this.form.rating,
                "watchCounter": this.form.watchCounter,
                "watchlist": this.form.watchlist,
                "type" : this.form.type
            };
            localStorage.setItem(formKey, JSON.stringify(fieldList));
        },
        clearStorage() {
            if(localStorage.getItem(formKey)) {
                localStorage.removeItem(formKey);
            }
        },
        handleCloseModal() {

          const entry = {
            "title": this.form.title,
            "ratingValue": this.form.rating,
            "watchCounter": this.form.watchCounter,
            "onWatchlist": this.form.watchlist
          }

          createDataEntryInDatabase(entry, this.form.type).then( response => {
            if(response.status === 200) {
              this.$emit('closeModal');
              this.clearStorage();
            }
            else {
              window.alert('Error while creating Data!')
            }
          })

        }
    },
    mounted() {
        if(localStorage.getItem(formKey)) {
            let entry = JSON.parse(localStorage.getItem(formKey));
            document.querySelector('#title').value = entry.title;
            this.form.title = entry.title;
            document.querySelector('#type').value = entry.type;
            this.form.type = entry.type;
            document.querySelector('#rating').value = entry.rating;
            this.form.rating = entry.rating;
            document.querySelector('#watchCounter').value = entry.watchCounter;
            this.form.watchCounter = entry.watchCounter;
            document.querySelector('#watchlist').value = entry.watchlist;
            this.form.watchlist = entry.watchlist;
        }
    },
    computed: {}
}
</script>

<template>
    <div class="backdrop-blur-sm w-full h-5/6 absolute z-20 flex">
        <div class="w-1/3 h-6/12 bg-gray-2 m-auto align-middle border-2 border-gray-1 rounded-lg shadow-lg">
            <!-- Modal Header -->
            <div class="flex items-center justify-between border-b border-gray-1 pl-6 pr-6 pt-4 pb-4">
                <h3 class="text-xl font-semibold text-blue-2">
                    Add New Entry
                </h3>
                <icon-button icon-class="bi bi-x-lg" tooltip="Close the modal"
                    additional-classes="text-blue-1 hover:text-blue-2" @click="this.$emit('closeModal')"></icon-button>
            </div>
            <!-- Modal Body -->
            <form class="p-6" @change="storeEntry()">
                <div class="grid gap-4 grid-cols-2">
                    <div class="col-span-2 mb-6">
                        <label for="title" class="block mb-2 text-xl font-medium text-blue-2">Title</label>
                        <input type="text" name="title" id="title"
                            class="bg-white border border-gray-1 text-blue-2 text-md rounded-lg focus:ring-green-1 focus:border-green-1 block w-full p-2.5"
                            placeholder="Type title of movie or series" required
                            v-model="form.title">
                    </div>
                    <div class="col-span-1 mb-8">
                        <label for="type" class="block mb-2 text-xl font-medium text-blue-2">Type</label>
                        <select required
                                id="type"
                                v-model="form.type"
                                class="bg-white border border-gray-1 text-blue-2 text-md rounded-lg focus:ring-green-1 focus:border-green-1 block w-full p-2.5"
                        >
                          <option value="" disabled>Type</option>
                          <option value="movie">Movie</option>
                          <option value="show">Show</option>
                        </select>
                    </div>
                    <div class="col-span-1 mb-8">
                        <label for="rating" class="block mb-2 text-xl font-medium text-blue-2">Rating: {{ form.rating
                        }}</label>
                        <input type="range" name="rating" id="rating"
                            class="bg-white border border-gray-1 text-blue-2 text-md rounded-lg focus:ring-green-1 focus:border-green-1 block w-full p-2.5"
                            max="100" min="0" step="1" value="1" required v-model="form.rating">
                    </div>
                    <div class="col-span-1 inline-flex items-center">
                        <label for="watchCounter"
                            class="text-xl font-medium text-blue-2 align-middle inline">Watchcounter</label>
                        <input type="number" name="watchCounter" id="watchCounter"
                            class="bg-white border border-gray-1 text-blue-2 text-md rounded-lg align-middle ml-4 w-1/6 text-center"
                            value="0" min="0" max="100" required v-model="form.watchCounter">
                    </div>
                    <div class="col-span-1">
                        <input type="checkbox" name="watchlist" id="watchlist" class="align-middle scale-150" value=""
                            required
                            v-model="form.watchlist">
                        <label for="watchlist" class="text-xl font-medium text-blue-2 align-middle ml-4">Watchlist</label>
                    </div>
                </div>
            </form>
            <!-- Modal Footer -->
            <div class="flex items-center justify-between border-t border-gray-1 pl-6 pr-6 pt-4 pb-4">
                <div class="col-span-1">
                    <icon-button icon-class="" text="Cancel" tooltip="Cancel the process"
                        additional-classes="bg-gray-1 text-blue-2 text hover:text-blue-1"
                        @click="this.$emit('closeModal'); clearStorage();"></icon-button>
                </div>
                <!-- TODO: add only clickable when all entered -> change opacity -->
                <div class="col-span-1">
                    <icon-button icon-class="" text="Add" tooltip="Add the entered titel"
                        additional-classes="bg-green-1 opacity-50 text-blue-2 text hover:text-blue-2"
                        @click="handleCloseModal"></icon-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
