<script>
import PercentageCircle from "@/components/PercentageCircle.vue";
import IconButton from "@/components/IconButton.vue";

export default {
  name: 'SearchResult',
  props: ["item"],
  components: {
    PercentageCircle,
    IconButton,
  }
}
</script>


<template>
    <div id="item" class="flex rounded-lg hover:bg-gray-1 p-2 min-h-24 items-center">
        <div class="inline-flex justify-between w-full">
            <span class="flex items-center w-1/4">{{ item.title }}</span>
            <span class="w-1/4">Rating: {{ item.ratingValue }}</span>
            <span class="w-1/4">Watchlist: {{ item.watchlist }}</span>
            <div class="text-center w-2/4">
              <span class="text-sm mr-2">Hinzugefügt am:</span>
              <span class="text-sm">{{ item.addDate }}</span>
            </div>
        </div>
    </div>
    <div id="seperator" class=" w-11/12 h-px bg-gray-1 mr-auto ml-auto"></div>
</template>
