<template>
  <div class="toggle-switch bg-gray-1 cursor-pointer w-20 h-10 rounded-full flex items-center p-1 transition-colors duration-300 ease-in-out" @click="toggle">
    <div :class="{'switch-active': isActive}" class="switch w-7 h-7 bg-gray-2 rounded-full transition-transform duration-300 ease-in-out flex justify-center items-center">
      <i :class="icon" class="iconState text-blue-1 text-lg"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      icon: 'bi bi-film'
    };
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
      this.icon = this.icon === 'bi bi-film' ? 'bi bi-tv' : 'bi bi-film';
      this.$emit('switchToggled', this.isActive);
    },
  },
};
</script>

<style>
.switch-active
{
  transform: translateX(40px);
}
</style>
