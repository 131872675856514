<script>
import IconButton from '@/components/IconButton.vue';
import SearchResult from '@/components/SearchResult.vue';
import ModalComponent from "@/components/ModalComponent.vue";
import { fetchDataFromDatabase } from "@/assets/serverAction";

export default {
  name: 'HeaderComponent',
  components: {
    SearchResult,
    IconButton,
    ModalComponent
  },
  data() {
    return {
      searchBarFocused: false,
      searchQuery: null,
      showModal: false,
      resources: null
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const moviesResponse = await fetchDataFromDatabase('movies');
        const showsResponse = await fetchDataFromDatabase('shows');

        if (moviesResponse.data && showsResponse.data) {
          this.resources = [...moviesResponse.data, ...showsResponse.data];
        } else {
          console.log('Error while fetching data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    triggerModal() {
      this.showModal = !this.showModal;
      console.log(`Modal: ${this.showModal}`);
    },
    triggerSearchResults() {
      setTimeout(() => {
        this.searchBarFocused = !this.searchBarFocused;
      }, 120);
    }
  },
  computed: {
    resultQuery() {
      if (!this.searchQuery || this.searchQuery.trim() === '') {
        return this.resources;
      } else {
        const searchTerm = this.searchQuery.toLowerCase().trim();
        return this.resources.filter(item => {
          return item.title.toLowerCase().includes(searchTerm);
        });
      }
    }
  }
}
</script>


<template>
  <div class="flex flex-row w-full bg-blue-2 p-2">
    <div class="basis-1/4 flex flex-row items-center">
      <router-link to="/" class="flex flex-row items-center">
        <img class="h-20 w-20 mr-2 animate-spin-slow" src="../assets/logo.png" alt="logo">
        <h1 class="tracking-widest font-bold text-3xl font-sans h-fit text-gray-2">Film.Heinz</h1>
      </router-link>
    </div>
    <div class="basis-1/2 flex items-center flex-row justify-around">
      <!--TODO: search bar in eigene component-->
      <input @focus="triggerSearchResults" @blur="triggerSearchResults" type="text" class="w-1/4 p-2 pl-4 rounded-xl bg-gray-1 focus:outline-none focus:bg-white transition-all ease-out focus:w-1/2" placeholder="Search..." v-model="searchQuery"/>
      <div v-if="searchBarFocused" class="search-results w-1/2 max-h-96 pb-4 pt-4 bg-gray-2 absolute top-20 shadow-lg rounded-xl border border-gray-1 overflow-y-scroll transition-all ease-in">
          <SearchResult v-for="(item, index) in resultQuery" :key="index" :item="item" class="w-full"/>
          <div v-if="searchQuery&&!resultQuery.length" class="text-center">
            <p>No results found!</p>
            <icon-button  text="Add a new Entry" tooltip="Open a modal to create a new entry" additional-classes="text-blue-1 bg-green-1 bg-opacity-50 w-auto mt-2" @click="this.$emit('openModal')"></icon-button>
          </div>
      </div>
    </div>
    <div class="basis-1/4 flex flex-row justify-end items-center">
      <div class="flex flex-row justify-between items-center ml-5">
        <router-link to="/watchlist">
          <icon-button  icon-class=""
                        text="Watchlist"
                        tooltip="Open your watchlist"
                        additional-classes="text-gray-2 hover:text-blue-1"
          ></icon-button>
        </router-link>
      </div>
      <router-link to="/profile" class="ml-5">
        <div class="flex flex-col align-middle">
          <icon-button  icon-class="bi bi-person"
                        tooltip="Open Profile Page"
                        additional-classes="text-gray-2 hover:text-blue-1 text-4xl px-2 py-2 mr-2"
          ></icon-button>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style scoped>

</style>
