<template>
  <svg class="stat-circle" width="100" height="100">
    <circle class="bg" cx="50" cy="50" r="46"></circle>
    <circle class="progress" cx="50" cy="50" r="46" :stroke-dasharray="circumference" :stroke-dashoffset="circumference" :data-percentage="percentage"></circle>
    <text x="50%" y="50%" dy=".3em" text-anchor="middle">{{ percentage }} / 100</text>
  </svg>
</template>

<script>
import gsap from "gsap";

export default {
  props: ["percentage"],
  mounted() {
    this.animateCircle();
  },
  computed: {
    circumference() {
      return 2 * Math.PI * 46;
    }
  },
  methods: {
    animateCircle() {
      var $statCircle = document.querySelectorAll(".stat-circle circle.progress");

      for (var circle of $statCircle) {
        var circumference = 2 * Math.PI * circle.r.baseVal.value;
        var off = circumference * (1 - parseFloat(circle.dataset.percentage) / 100);

        gsap.to(circle, {
          duration: 0.4,
          strokeDashoffset: off,
          rotation: -90,
          transformOrigin: "50% 50%"
        });
      }
    },
  }
}
</script>

<style scoped>
.stat-circle circle.bg {
  fill: none;
  stroke: #11506e;
  stroke-width: 6;
}

.stat-circle circle.progress {
  fill: none;
  stroke: #36e961;
  stroke-width: 9;
  stroke-linecap: round;
}

.stat-circle text {
  font-size: 18px;
  text-anchor: middle;
  fill: #11506e;
}
</style>
